<template>
   <div class="m-p">
      <Subscriptions />
   </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
name: 'Packages Subscriptions',
components: {
   Subscriptions: defineAsyncComponent( () => import('@/components/Profile/Subscriptions.vue') ),
},
}
</script>